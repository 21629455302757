const divideAmount = 1.9;

export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

export const getEdges = () => ({
  horizontalLeftEdge: -window.innerWidth / divideAmount,
  horizontalRightEdge: window.innerWidth / divideAmount,
  verticalTopEdge: window.innerHeight / divideAmount,
  verticalBottomEdge: -window.innerHeight / divideAmount,
});

export const rand = (min, max) => min + (max - min) * Math.random();

export const getOrthoCameraDims = () => ({
  left: window.innerWidth / -2,
  right: window.innerWidth / 2,
  top: window.innerHeight / 2,
  bottom: window.innerHeight / -2,
});

export const getAmountOfCircles = () => {
  const { innerWidth } = window;
  if (innerWidth < 700) return 50;
  if (innerWidth < 1000) return 100;
  return 250;
};
