import * as THREE from "three";

const darkVoid = "rgb(18, 18, 20)";
const turquoiseCold = "rgb(50, 177, 160)";
const frostyWhite = "rgb(245, 243, 234)";
const powderCake = "rgb(223, 216, 203)";
const deepSpaceDark = "rgb(20, 21, 29)";
const deepSpaceBlue = "rgb(54, 65, 86)";
const bubbleGumDark = "rgb(209, 86, 99)";
const retroYellow = "rgb(172, 175, 30)";
const fadedBlue = "rgb(90, 131, 178)";
const retroOrgane = "rgb(184, 119, 13)";

export const colors = {
  darkVoid: {
    name: "darkVoid",
    rgb: { r: 18, g: 18, b: 20 },
    rgbString: darkVoid,
    threeJS: new THREE.Color(darkVoid),
  },
  turquoiseCold: {
    name: "turquoiseCold",
    rgb: { r: 50, g: 177, b: 160 },
    rgbString: turquoiseCold,
    threeJS: new THREE.Color(turquoiseCold),
  },
  frostyWhite: {
    name: "frostyWhite",
    rgb: { r: 245, g: 243, b: 234 },
    rgbString: frostyWhite,
    threeJS: new THREE.Color(frostyWhite),
  },
  powderCake: {
    name: "powderCake",
    rgb: { r: 223, g: 216, b: 203 },
    rgbString: powderCake,
    threeJS: new THREE.Color(powderCake),
  },
  deepSpaceDark: {
    name: "deepSpaceDark",
    rgb: { r: 20, g: 21, b: 29 },
    rgbString: deepSpaceDark,
    threeJS: new THREE.Color(deepSpaceDark),
  },
  deepSpaceBlue: {
    name: "deepSpaceBlue",
    rgb: { r: 54, g: 65, b: 86 },
    rgbString: deepSpaceBlue,
    threeJS: new THREE.Color(deepSpaceBlue),
  },
  bubbleGumDark: {
    name: "bubbleGumDark",
    rgb: { r: 209, g: 86, b: 99 },
    rgbString: bubbleGumDark,
    threeJS: new THREE.Color(bubbleGumDark),
  },
  retroYellow: {
    name: "retroYellow",
    rgb: { r: 172, g: 175, b: 30 },
    rgbString: retroYellow,
    threeJS: new THREE.Color(retroYellow),
  },
  fadedBlue: {
    name: "fadedBlue",
    rgb: { r: 90, g: 131, b: 178 },
    rgbString: fadedBlue,
    threeJS: new THREE.Color(fadedBlue),
  },
  retroOrgane: {
    name: "retroOrgane",
    rgb: { r: 184, g: 119, b: 13 },
    rgbString: retroOrgane,
    threeJS: new THREE.Color(retroOrgane),
  },
};

export const theme = {
  background: colors.darkVoid,
  backgroundSecondary: colors.deepSpaceBlue,
  textLight: colors.frostyWhite,
  textDark: colors.darkVoid,
  accentColors: {
    primary: colors.turquoiseCold,
    secondary: colors.bubbleGumDark,
    tertiary: colors.retroYellow,
    quaternary: colors.fadedBlue,
    quinary: colors.retroOrgane,
  },
  accentColor: colors.turquoiseCold,
};
