import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPause,
  faPlay,
  faVolumeHigh,
  faVolumeOff,
  faForwardStep,
  faBackwardStep,
} from "@fortawesome/free-solid-svg-icons";
import {
  faGithubSquare,
  faLinkedin,
  faCodepen,
} from "@fortawesome/fontawesome-free-brands";

library.add(
  faPause,
  faPlay,
  faVolumeHigh,
  faVolumeOff,
  faForwardStep,
  faBackwardStep,
  faGithubSquare,
  faLinkedin,
  faCodepen
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
