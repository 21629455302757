import { clamp } from "./utils";

const getNextIndex = (currentIndex, indexModifier, musicLength) => {
  const nextIndex = currentIndex + indexModifier;
  if (nextIndex < 0) return musicLength - 1;
  if (nextIndex >= musicLength) return 0;
  return nextIndex;
};

class Loader {
  constructor() {
    this.callback = null;
  }

  async load(files) {
    const offlineAudioContext = new (window.OfflineAudioContext ||
      window.webkitOfflineAudioContext)(2, 44100 * 40, 44100);

    /* Load and Decode Files */
    const completedFiles = await Promise.all(
      files.map(async (file, i) => {
        const request = new Request(file.filePath);

        const arrayBuffer = await fetch(request, {
          headers: { "Content-Type": "audio/mpeg3" },
        }).then((res) => res.arrayBuffer());

        await offlineAudioContext.decodeAudioData(arrayBuffer, (buffer) => {
          file.buffer = buffer;
        });

        return file;
      })
    );

    this.complete(completedFiles);
    return true;
  }

  progress(callback) {
    this.callback = callback;
  }

  complete() {}
}

export default class AudioAnalyser {
  constructor() {
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    this.averageAmplitude = 0.2;
    this.beat = false;
    this.complexSpectrum = [];
    this.buffer = [];
    this.bpmRange = { low: 90, high: 180 };
    this.bpm = this.bpmRange.low;
    this.fftSize = 512;
    this.bufferLength = this.fftSize / 2;
    this.currentSong = null;
    this.currentlyPlaying = false;
    this.freqByteData = new Uint8Array(this.bufferLength);
    this.freqByteDataNormalizedToFoat = new Float32Array(this.bufferLength);
    this.freqFloatData = new Float32Array(this.bufferLength);
    this.timeByteData = new Uint8Array(this.bufferLength);
    this.timeFloatData = new Float32Array(this.bufferLength);
    this.loading = true;
    this.music = [];
    this.volume = 0.75;
  }

  async loadAudio(music) {
    this.loader = new Loader();
    //this.loader.progress((percent) => console.log(percent)); fix
    this.loader.complete = this.complete.bind(this);
    return await this.loader.load(music);
  }

  complete(files) {
    /* Store All Decoded Audio Files */
    console.log("== Loading Complete ==");
    console.log(files);
    const firstSong = files[0];
    this.music = files;
    this.currentSong = firstSong;
    this.loading = false;
    /* Temp fix */
    this.setupWebAudioNodes(this.currentSong);
  }

  setupWebAudioNodes(song) {
    this.songAudioNode = new Audio(song.filePath);
    this.song = this.songAudioNode;
    this.song.volume = this.volume;

    this.analyser = this.audioCtx.createAnalyser();
    this.analyser.smoothingTimeConstant = 0.4;
    this.analyser.fftSize = this.fftSize;

    this.source = this.audioCtx.createMediaElementSource(this.song);
    this.source.connect(this.analyser);

    this.source.connect(this.audioCtx.destination);

    this.freqByteDataNormalized = new Uint8Array(this.bufferLength);

    this.getBPM(song);
    //this.setupBeatTracking();
  }

  // setupBeatTracking() {
  //   this.mmllBeattracker = new MMLLBeatTracker(this.audioCtx.sampleRate);
  // }

  async onPlay() {
    this.setupWebAudioNodes(this.currentSong);

    try {
      await this.audioCtx.resume();
      await this.song.play();
    } catch (e) {
      console.log("error onPlay: ", e);
    }

    this.song.volume = this.volume;
    this.currentlyPlaying = !this.song.paused;
    return { currentlyPlaying: this.currentlyPlaying, song: this.song };
  }

  async onPause() {
    await this.audioCtx.suspend();
    this.song.pause();

    this.currentlyPlaying = !this.song.paused;
    return this.currentlyPlaying;
  }

  async onStopCurrentlyPlaying() {
    if (!this.audioCtx || !this.song) return;
    await this.audioCtx.suspend();
    this.song.pause();
  }

  async onPlayNext(direction) {
    const indexModifier = direction === "next" ? 1 : -1;
    const currentIndex = this.music.findIndex(
      (song) => song.title === this.currentSong.title
    );

    const nextIndex = getNextIndex(
      currentIndex,
      indexModifier,
      this.music.length
    );
    const nextSong = this.music[nextIndex];

    await this.onStopCurrentlyPlaying();

    this.setupWebAudioNodes(nextSong);
    this.currentSong = nextSong;

    await this.audioCtx.resume();
    await this.song.play();

    this.currentlyPlaying = !this.song.paused;
    return { nextSong, nextPlayerState: this.audioCtx.state };
  }

  async onPlayPrevious() {
    const currentIndex = this.music.findIndex(
      (song) => song.title === this.currentSong.title
    );
    const nextIndex = getNextIndex(currentIndex, -1, this.music.length);
    const nextSong = this.music[nextIndex];

    await this.onStopCurrentlyPlaying();

    this.setupWebAudioNodes(nextSong);
    this.currentSong = nextSong;

    await this.audioCtx.resume();
    await this.song.play();

    return { nextSong, nextPlayerState: this.audioCtx.state };
  }

  updateVolume(val) {
    const newVolume = clamp(val, 0, 1);
    this.volume = newVolume;
    this.song.volume = newVolume;
    return newVolume;
  }

  async getBPM(song) {
    /* Thanks Joesuls Guide http://joesul.li/van/beat-detection-using-web-audio/ */
    const threshold = 0.5;
    const filteredBuffer = await this.runBufferThroughLowPassFilter(
      song.buffer
    );
    const bufferData = filteredBuffer.getChannelData(0);
    const peaks = this.getPeaksAtThreshold(bufferData, threshold);
    const interals = this.countIntervalsBetweenNearbyPeaks(peaks);
    const tempoCounts = this.groupNeighborsByTempo(interals);

    tempoCounts.sort((a, b) => {
      return b.count - a.count;
    });

    if (tempoCounts.length > 0) {
      console.log(`=== Top BPM estimates for ${song.title} ===`);
      console.log(
        "First:",
        tempoCounts[0].tempo,
        "Second:",
        tempoCounts[1].tempo,
        "Third:",
        tempoCounts[2].tempo
      );
      this.bpm = tempoCounts[0].tempo;
    } else {
      console.log(`=== BPM estimates failed for ${song.title} ===`);
      this.bpm = (this.bpmRange.low + this.bpmRange.high) / 2;
    }
  }

  async runBufferThroughLowPassFilter(buffer) {
    const offlineAudioContext = new (window.OfflineAudioContext ||
      window.webkitOfflineAudioContext)(1, buffer.length, buffer.sampleRate);

    const source = offlineAudioContext.createBufferSource();
    source.buffer = buffer;

    const filter = offlineAudioContext.createBiquadFilter();
    filter.frequency.value = 200;
    filter.type = "lowpass";

    source.connect(filter);
    filter.connect(offlineAudioContext.destination);
    source.start(0);

    offlineAudioContext.oncomplete = (e) => {
      return e.renderedBuffer;
    };

    return await offlineAudioContext.startRendering();
  }

  getPeaksAtThreshold(data, threshold) {
    const { length } = data;
    let peaksArray = [];

    for (var i = 0; i < length; ) {
      if (data[i] > threshold) {
        peaksArray.push(i);
        // Skip forward ~ 1/4s to get past this peak.
        i += 10000;
      }
      i++;
    }

    return peaksArray;
  }

  groupNeighborsByTempo(intervalCounts) {
    let tempoCounts = [];
    intervalCounts.forEach((intervalCount, i) => {
      // Convert an interval to tempo
      let theoreticalTempo = 60 / (intervalCount.interval / 44100);
      theoreticalTempo = Math.round(theoreticalTempo);
      if (theoreticalTempo === 0) return;

      // Adjust the tempo to fit within the 90-180 BPM range
      while (theoreticalTempo < this.bpmRange.low) theoreticalTempo *= 2;
      while (theoreticalTempo > this.bpmRange.high) theoreticalTempo /= 2;

      const foundTempo = tempoCounts.some((tempoCount) => {
        if (tempoCount.tempo === theoreticalTempo) {
          tempoCount.count += intervalCount.count;
        }
        return tempoCount.tempo === theoreticalTempo;
      });

      if (!foundTempo) {
        tempoCounts.push({
          tempo: theoreticalTempo,
          count: intervalCount.count,
        });
      }
    });
    return tempoCounts;
  }

  countIntervalsBetweenNearbyPeaks(peaks) {
    let intervalCounts = [];
    peaks.forEach((peak, index) => {
      for (let i = 0; i < 10; i++) {
        const interval = peaks[index + i] - peak;

        const foundInterval = intervalCounts.some(
          (intervalCount) => intervalCount.interval === interval
        );

        if (!isNaN(interval) && interval !== 0 && !foundInterval) {
          intervalCounts.push({
            interval: interval,
            count: 1,
          });
        }
      }
    });
    return intervalCounts;
  }

  nomralizedValueByRange(value, rangeMin, rangeMax) {
    /* Normalizes value to 0 <-> 1 based on min/max of value */
    const normalizedValue = (value - rangeMin) / (rangeMax - rangeMin);
    return normalizedValue;
  }

  scaleValueToDesiredRange(value, minDesiredRange, maxDesiredRange) {
    const scaledValue =
      value * (maxDesiredRange - minDesiredRange) + minDesiredRange;
    return scaledValue;
  }

  convertFreqByteDataToNormalizedFloat() {
    const bufferMin = 0;
    const bufferMax = 175; // actually 255
    const minDesiredRange = 0.2;
    const maxDesiredRange = 0.9;

    /*Filter Out Non Measurements in the buffer */
    const filteredData = this.freqByteData.filter((value) => value !== 0);

    if (filteredData.length < 1) return 0.2;

    /*  Converts the entire data buffer into an average value */
    const avgValOfBuffer =
      this.freqByteData.reduce((a, b) => a + b) / this.freqByteData.length;

    /* Normalizes it to 0-1 */
    const normalizedFloat = this.nomralizedValueByRange(
      avgValOfBuffer,
      bufferMin,
      bufferMax
    );

    /* Scales normalized value to 0.2 <-> 0.9 which is better for opacity value */
    const floatScaled = this.scaleValueToDesiredRange(
      normalizedFloat,
      minDesiredRange,
      maxDesiredRange
    );

    return floatScaled;
  }

  update() {
    if (this.currentlyPlaying) {
      /* Update Current Time Of Song */

      /* Update the AnalyzerNodes Data */
      /* frequency domain vs time domain */
      this.analyser.getByteFrequencyData(this.freqByteData);
      /* Not Currently using freq float and time byte data
      this.analyser.getFloatFrequencyData(this.freqFloatData);
      this.analyser.getByteTimeDomainData(this.timeByteData);
      */

      /* Update beat tracker and detect beat */
      // const delayedStartIsOver = this.song.currentTime > 15;
      // const beat = this.mmllBeattracker.next(this.freqByteData);
      // if (beat && delayedStartIsOver) {
      //   this.beat = true;
      // } else {
      //   this.beat = false;
      // }

      /* Convert the byte data to normalized(0 - 1 values) Float32Array */
      const normalizedFloat = this.convertFreqByteDataToNormalizedFloat();
      this.averageAmplitude = normalizedFloat;
    } else {
      // Dim effects when paused
      this.averageAmplitude = 0.2;
      this.beat = false;
    }
  }
}
