import React, { useCallback, useEffect, useState } from "react";
import * as THREE from "three";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min";
import { ImprovedNoise } from "three/examples/jsm/math/ImprovedNoise";

import { fragmentShader, vertexShader } from "../../helpers/threeJsConfigs";
import {
  clamp,
  getEdges,
  rand,
  getOrthoCameraDims,
  getAmountOfCircles,
} from "../../helpers/utils";
import { colors } from "../../styles/theme";
const { deepSpaceDark } = colors;

//const raycaster = new THREE.Raycaster();
//const pointer = new THREE.Vector2();
const perlin = new ImprovedNoise();
const clock = new THREE.Clock();
let delta = clock.getDelta();

const { left, right, top, bottom } = getOrthoCameraDims();
const nearClipping = 0.1;
const farClipping = 1000;
const camera = new THREE.OrthographicCamera(
  left,
  right,
  top,
  bottom,
  nearClipping,
  farClipping
);
camera.position.z = 2;

const amount = getAmountOfCircles();
const interval = 1 / 60;
const CIRCLE_GEOMETRY = "Circle Geometry";
const LOADING_BARS = "Loading Bars";
const SHADER = "Shader";

const getChildren = (children, type) => {
  return children.filter((child) => child.type === type);
};

const updateLoader = (bars, audioAnalyser) => {
  const { loading } = audioAnalyser;
  if (bars.length === 0) return;
  if (!loading) return;

  let t = clock.getElapsedTime();

  bars.forEach((_, i) => {
    const bar = bars[i];
    const scaleY =
      perlin.noise(bar.userData.uvX * 226, 0, t * 1.25) * 0.5 + 0.5;
    bar.scale.y = (scaleY * window.innerHeight) / 1.5;
  });
};

const removeLoader = async (scene, TWEEN) => {
  const bars = getChildren(scene.children, LOADING_BARS);
  const firstAnimation = 750;
  const secondAnimation = 100;
  const thirdAnimation = 500;
  const fourthAnimation = 150;
  const totalAnimationLength =
    firstAnimation + secondAnimation + thirdAnimation + fourthAnimation;

  bars.forEach((_, i) => {
    const bar = bars[i];

    const scaleUp = new TWEEN.Tween(bar.scale).to(
      { y: window.innerHeight * 1.25 },
      750
    );

    const scaleWide = new TWEEN.Tween(bar.scale).to({ x: 1.5 }, 100);
    const wait = new TWEEN.Tween(bar.material).to(
      { opacity: bar.opacity },
      500
    );
    const fadeOut = new TWEEN.Tween(bar.material).to({ opacity: 0 }, 150);

    scaleUp.chain(scaleWide);
    scaleWide.chain(wait);
    wait.chain(fadeOut);

    fadeOut.onComplete(() => {
      scene.remove(bars[i]);
    });

    scaleUp.start();
  });

  await new Promise((resolve) => setTimeout(resolve, totalAnimationLength));

  return true;
};

const createLoader = (scene, accentColor) => {
  const boxWidth = 10;
  const numebrOfBars = Math.round(window.innerWidth / 2);

  for (let i = 0; i < numebrOfBars; i++) {
    const geometry = new THREE.BoxGeometry(boxWidth, 1);
    geometry.translate(0, 0.5, 0);

    const material = new THREE.MeshBasicMaterial({
      color: accentColor.threeJS,
      opacity: 0.75,
      transparent: true,
    });

    const bar = new THREE.Mesh(geometry, material);

    bar.type = LOADING_BARS;
    bar.position.x = i * 15;
    bar.position.y = -window.innerHeight / 2;
    bar.userData = {
      uvX: i / (numebrOfBars - 1),
    };

    scene.add(bar);
  }

  /* Shift Bars To Center */
  const bars = getChildren(scene.children, LOADING_BARS);
  bars.forEach((_, i) => {
    const bar = bars[i];
    bar.position.x -= (15 * numebrOfBars) / 2;
  });
};

const createCustomShader = (accentColor, scene) => {
  const mesh = new THREE.Mesh(
    new THREE.PlaneGeometry(2, 2), // just a quad
    new THREE.ShaderMaterial({
      // a very simple vertex shader is being used, that draws it full-screen
      vertexShader,
      fragmentShader,
      transparent: true, // enable transparency
      depthWrite: false, // disable depth testing/writing
      blending: THREE.AdditiveBlending,
      depthTest: false,
      uniforms: {
        resolution: {
          type: "v2",
          value: new THREE.Vector2(window.innerWidth, window.innerHeight),
        },
        opacity: {
          value: 0.0,
        },
        color: {
          value: new THREE.Vector3(
            accentColor.threeJS.r,
            accentColor.threeJS.g,
            accentColor.threeJS.b
          ),
        },
      },
    })
  );
  mesh.type = SHADER;
  scene.add(mesh);
};

const createCircleGeometry = (accentColor, scene) => {
  const {
    horizontalLeftEdge,
    horizontalRightEdge,
    verticalTopEdge,
    verticalBottomEdge,
  } = getEdges();

  // Create a set amount of circles for the background
  for (let i = 0; i < amount; i++) {
    const geometry = new THREE.CircleGeometry(25, 64);
    const material = new THREE.MeshBasicMaterial({
      color: accentColor.threeJS,
      transparent: true,
    });
    material.opacity = 0;
    // Create mesh and add to scene
    const circle = new THREE.Mesh(geometry, material);
    circle.type = CIRCLE_GEOMETRY;
    scene.add(circle);

    // Set inital Size
    const circleScale = rand(0.05, 0.3);
    circle.originalScale = { x: circleScale, y: circleScale };
    circle.scale.x = circleScale;
    circle.scale.y = circleScale;

    // Set inital opacity
    const opacity = rand(0.1, 0.2);

    new TWEEN.Tween(circle.material).to({ opacity }, 500);
    //circle.material.opacity = opacity;
    circle.material.originalOpacity = opacity;

    // Set initial position
    circle.position.x = rand(horizontalLeftEdge, horizontalRightEdge);
    circle.position.y = rand(verticalBottomEdge, verticalTopEdge);

    // Set Velocity
    circle.velocity = { x: rand(-0.65, 0.65), y: rand(0.5, 1.5) };
  }
};

// const createStats = () => {
//   const fpsStats = new Stats();
//   const memoryStats = new Stats();
//   fpsStats.showPanel(2);
//   memoryStats.showPanel(0);
//   fpsStats.domElement.style.cssText = "position:absolute;top:0px;right:0px;";
//   memoryStats.domElement.style.cssText =
//     "position:absolute;top:0px;right:80px;";
//   document.body.appendChild(fpsStats.dom);
//   document.body.appendChild(memoryStats.dom);
//   return { fpsStats, memoryStats };
// };

const updateCirclePosition = (circle, velocityModifier) => {
  /* Use tempo to determine velocity */
  circle.position.y -= circle.velocity.y * velocityModifier;
  circle.position.x -= circle.velocity.x * velocityModifier;
};

const updateCircleOpacity = (circle, averageAmplitude) => {
  new TWEEN.Tween(circle.material).to({ opacity: averageAmplitude }, 5).start();
};

// const updateCircleToBeat = (circle, beat, index, averageAmplitude) => {
//   /* Only update 1 in 10 circles when the beat hits */
//   const updateThisCircle = beat && averageAmplitude > 0.05 && index % 5 === 0;

//   const newXScale = updateThisCircle
//     ? circle.originalScale.x + 1
//     : circle.originalScale.x;
//   const newYScale = updateThisCircle
//     ? circle.originalScale.y + 1
//     : circle.originalScale.y;

//   new TWEEN.Tween(circle.scale)
//     .to(
//       {
//         x: newXScale,
//         y: newYScale,
//       },
//       15
//     )
//     .start();

//   if (beat && averageAmplitude > 0.05)
//     new TWEEN.Tween(circle.material).to({ opacity: 1 }, 50).start();
// };

const updateCircleIfOffCanvas = (circle, position) => {
  const {
    horizontalLeftEdge,
    horizontalRightEdge,
    verticalTopEdge,
    verticalBottomEdge,
  } = getEdges();

  if (position.x < horizontalLeftEdge || position.x > horizontalRightEdge) {
    position.x *= -1;
  }

  if (circle.position.y < verticalBottomEdge) {
    position.y = verticalTopEdge;
    position.x = rand(horizontalLeftEdge, horizontalRightEdge);
  }
};

const updateMaterialsAccentColor = (circles, edgeGlow, accentColor) => {
  circles.forEach((child, i) => {
    const circle = circles[i];
    circle.material.color = accentColor.threeJS;
  });
  /* If cirlces color change the shader needs updated too */
  edgeGlow.material.uniforms.color.value = new THREE.Vector3(
    accentColor.threeJS.r,
    accentColor.threeJS.g,
    accentColor.threeJS.b
  );
};

const checkIfCirclesColorNeedUpdated = (circles, accentColor) => {
  if (circles.length === 0) return;
  const circlesColor = circles[0].material.color;
  const circlesColorValues = Object.values(circlesColor);
  const accentColorValues = Object.values(accentColor.threeJS);
  return circlesColorValues.some((val, i) => val !== accentColorValues[i]);
};

const updateCircles = (audioAnalyser, scene) => {
  if (scene.children.length < 1) return;

  const circles = getChildren(scene.children, CIRCLE_GEOMETRY);

  const { averageAmplitude, /*beat,*/ bpm, bpmRange } = audioAnalyser;

  const normalizedBpm = (bpm - bpmRange.low) / (bpmRange.high - bpmRange.low);

  circles.forEach((_, i) => {
    const circle = circles[i];
    const { position } = circle;
    updateCirclePosition(circle, normalizedBpm);
    updateCircleOpacity(circle, averageAmplitude);
    //updateCircleToBeat(circle, beat, i, averageAmplitude);
    updateCircleIfOffCanvas(circle, position);
  });
};

const updateEdgeGlow = (audioAnalyser, scene) => {
  if (scene.children.length < 1) return;

  const edgeGlow = getChildren(scene.children, SHADER)[0];

  if (!edgeGlow) return;

  const { averageAmplitude } = audioAnalyser;
  const opacity = parseFloat(clamp(averageAmplitude / 2, 0.1, 0.8));

  new TWEEN.Tween(edgeGlow.material.uniforms.opacity)
    .to({ value: opacity }, 75)
    .start();
};

// const updateRayCasting = (camera, scene) => {
//   const circles = getChildren(scene.children, CIRCLE_GEOMETRY);

//   raycaster.setFromCamera(pointer, camera);

//   const intersects = raycaster.intersectObjects(circles, false);

//   if (intersects.length > 0) {
//     intersects.forEach((_, i) => {
//       const circle = circles[i];
//       circle.material.color = colors.retroYellow.threeJS;
//     });
//   }
// };

const onWindowResize = (camera, renderer) => {
  const { innerHeight, innerWidth } = window;
  const { left, right, top, bottom } = getOrthoCameraDims();

  camera.left = left;
  camera.right = right;
  camera.top = top;
  camera.bottom = bottom;

  camera.updateProjectionMatrix();
  renderer.setSize(innerWidth, innerHeight);
};

// const onPointerMove = (event) => {
//   pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
//   pointer.y = -(event.clientY / window.innerHeight) * 2 + 1;
// };

const Visuals = ({
  accentColor,
  audioAnalyser,
  loading,
  loadingComplete,
  setLoadingComplete,
  updateAudioManager,
}) => {
  const [scene, setScene] = useState();

  useEffect(() => {
    /* Create Debug Stats */
    //const { fpsStats, memoryStats } = createStats();

    /* Create Renderer */
    const renderer = new THREE.WebGLRenderer({
      antialias: true,
    });
    renderer.setSize(window.innerWidth, window.innerHeight + 150);
    document.body.appendChild(renderer.domElement);

    /* Create Scene */
    const scene = new THREE.Scene();
    scene.background = deepSpaceDark.threeJS;
    setScene(scene);

    /* Create Loader */
    createLoader(scene, accentColor);

    /* Selecting children outside of the render loop for performance gain */
    const bars = getChildren(scene.children, LOADING_BARS);

    /* Render Loop */
    const render = () => {
      // fpsStats.update();
      // memoryStats.update();
      updateCircles(audioAnalyser, scene);
      updateEdgeGlow(audioAnalyser, scene);
      updateLoader(bars, audioAnalyser);
      //updateRayCasting(camera, scene);

      TWEEN.update();
      renderer.render(scene, camera);
    };

    /* Create Animation Loop */
    const animate = () => {
      render();
      delta += clock.getDelta();
      if (delta > interval) {
        // Limits Analyser Updates to 60 times a second
        updateAudioManager();
        delta = delta % interval;
      }
    };

    renderer.setAnimationLoop(animate);

    /* Add Window Event Listeners */
    window.addEventListener(
      "resize",
      () => onWindowResize(camera, renderer),
      false
    );
    //document.addEventListener("mousemove", (e) => onPointerMove(e), false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanUpLoader = useCallback(async () => {
    const loadingComplete = await removeLoader(scene, TWEEN);
    setLoadingComplete(loadingComplete);
  }, [scene, setLoadingComplete]);

  useEffect(() => {
    if (!scene || scene.children.length < 1) return;

    const edgeGlow = getChildren(scene.children, SHADER)[0];
    const circles = getChildren(scene.children, CIRCLE_GEOMETRY);
    const colorNeedsUpdated = checkIfCirclesColorNeedUpdated(
      circles,
      accentColor
    );

    if (colorNeedsUpdated) {
      updateMaterialsAccentColor(circles, edgeGlow, accentColor);
    }
  }, [accentColor, scene]);

  useEffect(() => {
    if (!loading) {
      cleanUpLoader();
    }
  }, [loading, scene, cleanUpLoader]);

  useEffect(() => {
    if (loadingComplete) {
      createCircleGeometry(accentColor, scene);
      createCustomShader(accentColor, scene);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingComplete, scene]);

  return <></>;
};

export default Visuals;
