import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ColorSwitcher from "../ColorSwitcher/";
import { FlexContainer } from "../../styles/shared";
import { UnstyledLinkTag } from "../../styles/shared";
import {
  ControlsBox,
  FlexContainerBottom,
  FlexContainerOverlay,
  FontAwesomeSocialIcon,
  Subtitle,
  Title,
  TitlesWrapper,
  SliderBox,
  VolumeSlider,
} from "./styles";

const Overlay = ({
  accentColor,
  currentSong,
  play,
  pause,
  loading,
  musicPlaying,
  playNext,
  subtitles,
  switchAccentColor,
  updateVolume,
  volume,
}) => {
  const [titleIndex, setTitleIndex] = useState(0);
  const [firstRun, setFirstRun] = useState(true);

  useEffect(() => {
    const delay = firstRun ? 3500 : 2500;
    const rotateTitle = setInterval(() => {
      const nextIndex = titleIndex + 1;
      setTitleIndex(nextIndex >= subtitles.length ? 0 : nextIndex);
      setFirstRun(false);
    }, delay);
    return () => clearInterval(rotateTitle);
  }, [titleIndex, subtitles, firstRun, setFirstRun]);

  const renderPlayPauseButton = () => {
    if (musicPlaying) {
      return (
        <FontAwesomeIcon
          onClick={() => !loading && pause()}
          icon="fa-solid fa-pause"
          size="sm"
          color={accentColor.rgbString}
          style={{ cursor: "pointer" }}
        />
      );
    }
    return (
      <FontAwesomeIcon
        onClick={() => !loading && play()}
        icon="fa-solid fa-play"
        size="sm"
        color={accentColor.rgbString}
        style={{ cursor: "pointer" }}
      />
    );
  };

  return (
    <FlexContainerOverlay
      id="header"
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
    >
      <FlexContainer
        justifyContent="space-between"
        style={{ padding: "0px 20px 0px 20px" }}
      >
        <ColorSwitcher active={accentColor} onChange={switchAccentColor} />
        <FlexContainer justifyContent="space-between" width="120px">
          <UnstyledLinkTag href="https://github.com/crafty" target="_blank">
            <FontAwesomeSocialIcon icon="fa-brands fa-github-square" />
          </UnstyledLinkTag>
          <UnstyledLinkTag
            href="https://www.linkedin.com/in/craycraftdan/"
            target="_blank"
          >
            <FontAwesomeSocialIcon icon="fa-brands fa-linkedin" />
          </UnstyledLinkTag>
          <UnstyledLinkTag href="https://codepen.io/craycrafty" target="_blank">
            <FontAwesomeSocialIcon icon="fa-brands fa-codepen" />
          </UnstyledLinkTag>
        </FlexContainer>
      </FlexContainer>
      <FlexContainer flexDirection={"column"}>
        <TitlesWrapper>
          <Title>CRAYCRAFT</Title>
          <Subtitle>{subtitles[titleIndex]}</Subtitle>
        </TitlesWrapper>
      </FlexContainer>
      <FlexContainerBottom
        justifyContent="space-between"
        alignItems="flex-end"
        height="fit-content"
      >
        <FlexContainer
          flexDirection="Column"
          alignItems="flex-start"
          width="fit-content"
          style={{
            color: accentColor.rgbString,
          }}
        >
          <div style={{ fontSize: "1.3rem" }}>
            {currentSong?.title || "N/A"}{" "}
          </div>
          <div style={{ fontSize: "1.05rem" }}>
            {" "}
            {currentSong?.artist || "N/A"}
          </div>
        </FlexContainer>

        <ControlsBox>
          <FontAwesomeIcon
            icon="fa-solid fa-backward-step"
            size="sm"
            color={accentColor.rgbString}
            style={{ cursor: "pointer" }}
            onClick={() => !loading && playNext("previous")}
          />
          {renderPlayPauseButton()}
          <FontAwesomeIcon
            icon="fa-solid fa-forward-step"
            size="sm"
            color={accentColor.rgbString}
            style={{ cursor: "pointer" }}
            onClick={() => !loading && playNext("next")}
          />
        </ControlsBox>

        <FlexContainer width="fit-content">
          <FontAwesomeIcon
            icon="fa-solid fa-volume-off"
            size="xs"
            color={accentColor.rgbString}
          />
          <SliderBox>
            <VolumeSlider
              type="range"
              id="volume"
              name="cowbell"
              min="0"
              max="1"
              step="0.1"
              value={volume}
              onChange={(e) => updateVolume(e.target.value)}
            />
          </SliderBox>
          <FontAwesomeIcon
            icon="fa-solid fa-volume-high"
            size="xs"
            color={accentColor.rgbString}
          />
        </FlexContainer>
      </FlexContainerBottom>
    </FlexContainerOverlay>
  );
};

export default Overlay;
