export const vertexShader = `
  precision highp float;
  varying vec2 vUv;

  void main() {
    vUv = uv.xy;

    gl_Position = vec4(position,1);
  }
`;

export const fragmentShader = `
  precision mediump float;
  varying vec2 vUv;
  uniform vec2 resolution;
  uniform float opacity;
  uniform vec3 color;

  float Circle(vec2 uv, vec2 pos, float radius, float blur) {
    float distance = length(uv - pos);
    float circle = smoothstep(radius, radius - blur, distance);
    return circle;
  }

  void main(){
    vec2 uv = vUv;

    uv -= .5;
    uv.x *= resolution.x / resolution.y;
    
    vec2 posTopLeftCorner = vec2(uv.x, -0.5);

    float circle = Circle(uv, posTopLeftCorner, 0.2, 0.5); 

    vec3 col = color*circle;

    gl_FragColor  = vec4(vec3(col), opacity);
  }
`;
