import React from "react";
import { theme } from "../../styles/theme";
import { ColorSwitcherContainer, OptionBox, Option } from "./styles";

const { accentColors } = theme;
const colors = Object.values(accentColors);

const ColorSwitcher = ({ onChange }) => (
  <ColorSwitcherContainer>
    {colors.map((color, i) => (
      <OptionBox key={color.name}>
        <Option
          delay={(i + 0.1) / 2}
          length={colors.length - 1}
          color={color.rgbString}
          onClick={() => onChange(color)}
        />
      </OptionBox>
    ))}
  </ColorSwitcherContainer>
);

export default ColorSwitcher;
