import styled from "styled-components";

export const FlexContainer = styled.div`
  display: ${(props) => props.display || "flex"};
  flex: ${(props) => props.flex || "0 1 auto"};
  flex-direction: ${(props) => props.flexDirection || "row"};
  justify-content: ${(props) => props.justifyContent || "center"};
  align-items: ${(props) => props.alignItems || "center"};
  width: ${(props) => props.width || "100%"};
  height: ${(props) => props.height || "fit-content"};
  ${(props) => props.debug && "border: 1px solid yellow"}
`;

export const UnstyledLinkTag = styled.a`
  color: transparant;
  text-decoration: none;
`;
