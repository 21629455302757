import { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Overlay from "./components/Overlay/";
import Visuals from "./components/Visuals/";
import useAudioManager from "./hooks/audioManagerHook";
import { colors, theme } from "./styles/theme";

const AppContainer = styled.div`
  width: 100%;
  height: 100dvh;
  font-size: 20px;
  position: absolute;
  color: white;
  text-align: center;
`;

const subtitles = [
  "Pretentious Title Curator",
  "JavaScript Aficionado",
  "Video Game Sommelier",
  "Professional Computer Nerd",
];

const App = () => {
  const [accentColor, setAccentColor] = useState(colors.turquoiseCold);
  const [loadingComplete, setLoadingComplete] = useState(false);

  const {
    audioAnalyser,
    averageAmplitude,
    beat,
    currentSong,
    pause,
    play,
    playNext,
    loading,
    musicPlaying,
    updateVolume,
    updateAudioData,
    volume,
  } = useAudioManager();

  const switchAccentColor = (color) => {
    setAccentColor(color);
  };

  return (
    <AppContainer>
      <ThemeProvider theme={{ ...theme, accentColor }}>
        {!loading && loadingComplete && (
          <Overlay
            accentColor={accentColor}
            currentSong={currentSong}
            pause={pause}
            play={play}
            loading={loading}
            musicPlaying={musicPlaying}
            playNext={playNext}
            subtitles={subtitles}
            switchAccentColor={switchAccentColor}
            updateVolume={updateVolume}
            volume={volume}
          />
        )}
        <Visuals
          accentColor={accentColor}
          audioAnalyser={audioAnalyser}
          averageAmplitude={averageAmplitude}
          beat={beat}
          loading={loading}
          loadingComplete={loadingComplete}
          setLoadingComplete={setLoadingComplete}
          updateAudioManager={updateAudioData}
        />
      </ThemeProvider>
    </AppContainer>
  );
};

export default App;
