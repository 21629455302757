import hydrogenFile from "./hydrogen.mp3";
import googlingFile from "./googling.mp3";
import rosesFile from "./roses.mp3";

const music = [
  { title: "Googling", artist: "vaxXxed", filePath: googlingFile },
  { title: "Roses", artist: "Mac Miller", filePath: rosesFile },
  { title: "Hydrogen", artist: "M.O.O.N.", filePath: hydrogenFile },
];

export default music;
