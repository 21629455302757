import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { rubberBand } from "../../styles/transitions";
import { typeIn, fadeIn } from "../../styles/animations";
import { FlexContainer } from "../../styles/shared";

export const FontAwesomeSocialIcon = styled(FontAwesomeIcon)`
  font-size: 2.5rem;
  color: ${(props) => props.theme.accentColor.rgbString};
  opacity: 0.7;
  cursor: pointer;
  transition: all 0.5s ${rubberBand};

  &:hover {
    opacity: 1;
    transform: scale(1.3);
  }
`;

export const FlexContainerOverlay = styled(FlexContainer)`
  opacity: 0;
  animation: ${fadeIn} 1.5s 0.2s ease 1;
  animation-fill-mode: forwards;
`;

export const Title = styled.div`
  font-size: 6rem;
  font-weight: 700;
  letter-spacing: 2rem;
  opacity: 0.7;
  user-select: none;
  translation: transformX(-1.5rem);
  transition: all 1.5s ${rubberBand};

  @media only screen and (max-width: 600px) {
    font-size: 3rem;
    letter-spacing: 0.75rem;
  }
`;

export const Subtitle = styled.div`
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 0.5rem;
  text-align: left;
  opacity: 0.7;
  padding-left: 1rem;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  animation: ${typeIn} 2s steps(18);
  transition: all 1.5s ${rubberBand};

  @media only screen and (max-width: 600px) {
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
  }
`;

export const TopContainer = styled.div`
  width: 100%;
  height: 30px;
`;

export const TitlesWrapper = styled.div`
  width: fit-content;
  align-self: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  opacity: 0;
  animation: ${fadeIn} 1.5s 0.2s ease 1;
  animation-fill-mode: forwards;

  :hover * {
    color: ${(props) => props.theme.accentColor.rgbString} !important;
    opacity: 1;
  }
  :hover ${Title} {
    letter-spacing: 2.8rem;
    opacity: 1;
  }
  :hover ${Subtitle} {
    letter-spacing: 0.8rem;
    opacity: 1;
  }

  @media only screen and (max-width: 600px) {
    :hover ${Title} {
      letter-spacing: 1.5rem;
      opacity: 1;
    }
    :hover ${Subtitle} {
      letter-spacing: 0.4rem;
      opacity: 1;
    }
  }
`;

export const FlexContainerBottom = styled(FlexContainer)`
  align-items: center;
  background: ${(props) => props.theme.background.rgbString};
  opacity: 0.9;
  position: relative;
  padding: 20px 20px 20px 20px;
`;

export const ControlsBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100px;
  position: absolute;
  left: calc(50% - 50px);

  @media only screen and (max-width: 600px) {
    width: 70px;
    left: calc(50% - 35px);
  }
`;

export const SliderBox = styled.div`
  display: flex;
  width: 80px;
  margin: 0px 10px 0px 10px;

  @media only screen and (max-width: 600px) {
    width: 40px;
  }
`;

export const VolumeSlider = styled.input`
  /* OG styles are removed app wide in index.css */
  display: inline-block;
  margin-top: 0.05rem;

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border-radius: 100%;
    background: ${(props) => props.theme.accentColor.rgbString};
    cursor: pointer;
    margin-top: -0.5rem; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }

  /* All the same stuff for Firefox */
  ::-moz-range-thumb {
    height: 14px;
    width: 14px;
    border-radius: 100%;
    background: ${(props) => props.theme.accentColor.rgbString};
    cursor: pointer;
  }

  /* All the same stuff for IE */
  ::-ms-thumb {
    height: 14px;
    width: 14px;
    border-radius: 100%;
    background: ${(props) => props.theme.accentColor.rgbString};
    cursor: pointer;
  }

  ::-webkit-slider-runnable-track {
    height: 0.4rem;
    cursor: pointer;
    background: ${(props) => props.theme.accentColor.rgbString};
    border-radius: 2px;
  }

  ::-moz-range-track {
    height: 0.4rem;
    cursor: pointer;
    background: ${(props) => props.theme.accentColor.rgbString};
    border-radius: 2px;
  }

  ::-ms-track {
    height: 0.4rem;
    cursor: pointer;
    background: ${(props) => props.theme.accentColor.rgbString};
    border-radius: 2px;
  }
`;
