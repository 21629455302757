import { useEffect, useRef, useState } from "react";
import AudioAnalyser from "../helpers/audioManager.js";
import music from "../assets/music/";

const useAudioManager = () => {
  const audioAnalyserRef = useRef(new AudioAnalyser());
  const audioAnalyser = audioAnalyserRef.current;
  const { currentlyPlaying } = audioAnalyser;

  const [currentSong, setCurrentsong] = useState(audioAnalyser.currentSong);
  const [loading, setLoading] = useState(audioAnalyser.loading);
  const [musicPlaying, setMusicPlaying] = useState(currentlyPlaying);
  const [volume, setVolume] = useState(audioAnalyser.volume);

  useEffect(() => {
    loadAudio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAudio = async () => {
    await audioAnalyser.loadAudio(music);
    setLoading(false);
    setCurrentsong(audioAnalyser.currentSong);
  };

  const play = async () => {
    const nextPlayerState = await audioAnalyser.onPlay();
    setMusicPlaying(nextPlayerState);
  };

  const pause = async () => {
    const nextPlayerState = await audioAnalyser.onPause();
    setMusicPlaying(nextPlayerState);
  };

  const updateVolume = (val) => {
    audioAnalyser.updateVolume(val);
    setVolume(val);
  };

  const playNext = async (direction) => {
    const { nextSong, nextPlayerState } = await audioAnalyser.onPlayNext(
      direction
    );
    setCurrentsong(nextSong);
    setMusicPlaying(nextPlayerState);
  };

  const updateAudioData = () => {
    audioAnalyser.update();
  };

  return {
    audioAnalyser,
    currentSong,
    playNext,
    loading,
    musicPlaying,
    play,
    pause,
    updateVolume,
    volume,
    updateAudioData,
  };
};

export default useAudioManager;
